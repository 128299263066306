import {
  FC,
  useState,
  useEffect,
  useContext,
  createContext,
  ReactNode,
  useCallback,
} from 'react';
import { ethers } from 'ethers';
import { CACHE_WALLET_KEY, NETWORK_MAINNET } from 'config';
import cache from 'utils/cache';

import WalletConnectProvider from '@walletconnect/web3-provider';

const WalletContext = createContext<{
  network: string | null;

  signer: ethers.Signer | null;
  address: string | null;

  isConnecting: boolean;
  startConnecting: () => void;
  stopConnecting: () => void;
  disconnect: () => void;

  connectMetamask: () => void;
  connectWalletConnect: () => void;
} | null>(null);

export const WalletProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isConnecting, setIsConnecting] = useState(false);
  const [network, setNetwork] = useState<string | null>(null);
  const [signer, setSigner] = useState<ethers.Signer | null>(null);
  const [address, setAddress] = useState<string | null>(null);

  const startConnecting = useCallback(() => setIsConnecting(true), [
    setIsConnecting,
  ]);
  const stopConnecting = useCallback(() => setIsConnecting(false), [
    setIsConnecting,
  ]);

  const setProvider = useCallback(
    async (web3Provider: any) => {
      web3Provider.on('accountsChanged', () => {
        window.location.reload();
      });
      web3Provider.on('chainChanged', () => {
        window.location.reload();
      });
      web3Provider.on('disconnect', () => {
        Promise.resolve(disconnect());
      });
      const provider = new ethers.providers.Web3Provider(web3Provider);

      const { name: network } = await provider.getNetwork();
      setNetwork(~['homestead'].indexOf(network) ? NETWORK_MAINNET : network);

      const signer = provider.getSigner();

      let address = await signer.getAddress();
      setSigner(signer);
      setAddress(address);
      stopConnecting();
      // console.log('signer', {
      //   signer,
      //   address,
      //   network,
      // });
    },
    [stopConnecting]
  );

  const connectMetamask = useCallback(async () => {
    if (window.ethereum) {
      try {
        // @ts-ignore
        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
        cache(CACHE_WALLET_KEY, 'metamask');
        await setProvider(window.ethereum);
      } catch (error) {
        if (error.code === 4001) {
          // User rejected request
        }

        // setError(error);
      }
    }
    // await window.ethereum.enable();

    // @ts-ignore
    // const accounts = await window.ethereum.send('eth_requestAccounts');

    // cache(CACHE_WALLET_KEY, 'metamask');
    // await setProvider(window.ethereum);
  }, [setProvider]);

  const connectWalletConnect = useCallback(async () => {
    //  Create WalletConnect Provider
    const provider = new WalletConnectProvider({
      infuraId: 'fde66326e4394009aa4fc8faef68a001',
      // infuraId: '27e484dcd9e3efcfd25a83a78777cdf1',
      // qrcode: false,
      chainId: 1,
      qrcodeModalOptions: {
        mobileLinks: ['metamask', 'trust'],
      },
    });
    // if (!window.ethereum) return;
    // await window.ethereum.enable();
    // cache(CACHE_WALLET_KEY, 'metamask');
    // await provider.disconnect();
    // await provider.disconnect();
    // await provider.close();
    await provider
      .enable()
      .catch(async () => {
        await provider.disconnect();
      })
      .then(async () => {
        await setProvider(provider);
      });

    //  Enable session (triggers QR Code modal)
  }, [setProvider]);

  async function disconnect() {
    await cache(CACHE_WALLET_KEY, null);
    setSigner(null);
    setAddress(null);
    setNetwork(null);
  }

  useEffect(() => {
    const load = async () => {
      if (address) return;

      const cachedWallet = cache(CACHE_WALLET_KEY);
      if (cachedWallet) {
        const c: Record<string, () => void> = {
          metamask: connectMetamask,
        };
        c[cachedWallet]?.();
      }
    };

    load();
  }, [address, connectMetamask]);

  return (
    <WalletContext.Provider
      value={{
        network,

        signer,
        address,

        isConnecting,
        startConnecting,
        stopConnecting,
        disconnect,

        connectMetamask,
        connectWalletConnect,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export function useWallet() {
  const context = useContext(WalletContext);
  if (!context) {
    throw new Error('Missing Wallet context');
  }
  const {
    network,

    signer,
    address,

    isConnecting,
    startConnecting,
    stopConnecting,
    disconnect,

    connectMetamask,
    connectWalletConnect,
  } = context;

  return {
    network,

    signer,
    address,

    isConnecting,
    startConnecting,
    stopConnecting,
    disconnect,

    connectMetamask,
    connectWalletConnect,
  };
}
