import { FC } from 'react';
// import { FC, useCallback, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
// import * as ethers from 'ethers';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import Table from '@material-ui/core/Table';
// import TableHead from '@material-ui/core/TableHead';
// import TableBody from '@material-ui/core/TableBody';
// import TableRow from '@material-ui/core/TableRow';
// import TableCell from '@material-ui/core/TableCell';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
// import InputLabel from '@material-ui/core/InputLabel';
// import FormControl from '@material-ui/core/FormControl';
// import Tooltip from '@material-ui/core/Tooltip';
import Alert from '@material-ui/lab/Alert';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import Fab from '@material-ui/core/Fab';
// import Paper from '@material-ui/core/Paper';
// import Popover from '@material-ui/core/Popover';
// import InfoIcon from '@material-ui/icons/Info';
// import AddIcon from '@material-ui/icons/Add';
// import AddIcon from '@material-ui/icons/Add';
// import AddBoxIcon from '@material-ui/icons/AddBox';
// import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
// import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
// import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
// import Grid from '@material-ui/core/Grid';
import { APP_NAME, APP_HEADER, FEE, NETWORK_MAINNET } from 'config';

// import moment from 'moment';

// import { timeAgo, formatTimestamp } from 'utils/time';
import { useWallet } from 'contexts/wallet';
import { useContracts } from 'contexts/contracts';
import { useNotifications } from 'contexts/notifications';
import { useData } from 'contexts/data';
// import { LiquidityPosition } from 'utils/types';
// import { formatUnits } from 'utils/big-number';
// import LiquidityPositionTableRow from 'components/shared/LiquidityPositionTableRow';
import LiquidityCard from 'components/shared/LiquidityCard';
import AdsLiquidityCard from 'components/shared/AdsLiquidityCard';

import LinearProgress from '@material-ui/core/LinearProgress';

export const useStyles = makeStyles((theme) => ({
  title: {},
  description: {},
  maxButton: {
    height: 35,
  },
  depositButtonCell: {
    width: 110,
    padding: 5,
  },
  depositButton: {
    width: 100,
  },
  boxContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: 10,
    },
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  depositLp: {
    backgroundColor: 'transparent',
  },
}));

const Stake: FC<{ history: any }> = ({ history }) => {
  const classes = useStyles();
  const { startConnecting: startConnectingWallet, address } = useWallet();
  const {
    token0Address,
    token1Address,
    token0Symbol,
    token1Symbol,
  } = useContracts();
  const {
    positions,
    currentIncentiveId,
    currentAdsIncentiveId,
    incentives,
    adsincentives,
    globalData,
    loading,
    unigraphData,
    balance,
    setCurrentIncentiveId,
  } = useData();

  const { showSuccessNotification } = useNotifications();

  let { network } = useWallet();
  network = network || NETWORK_MAINNET;
  // console.log('positions', {
  //   balance,
  //   positions,
  //   currentIncentiveId,
  //   incentives,
  //   setCurrentIncentiveId,
  // });
  // console.log('xxx loading', {
  //   loading,
  // });

  // if (globalData && globalData.force) {
  //   // showSuccessNotification('a', 'b');
  // }
  const getLPlink = `https://app.uniswap.org/#/add/${[
    token1Symbol === 'ETH' ? 'ETH' : token1Address,
    token0Address,
    FEE[network],
  ].join('/')}`;

  return (
    <>
      <Box className={classes.boxContainer} p={5} pt={0}>
        <Box mb={10}>
          <Typography
            align={'center'}
            variant='h4'
            component='h1'
            className={classes.title}
            color='textPrimary'
            gutterBottom
          >
            {APP_NAME}
          </Typography>

          <Typography
            align={'center'}
            variant='h6'
            component='h2'
            className={classes.description}
            color='textPrimary'
            gutterBottom
          >
            {APP_HEADER}
          </Typography>
        </Box>

        {!address ? (
          <>
            {/*<Box>*/}
            {/*  <Typography variant='h5'>*/}
            {/*    Staking MFG tokens on UNISwap v3 to earn MFG Token*/}
            {/*  </Typography>*/}
            {/*</Box>*/}
            {/*<img width={400} src={'/images/mfg-wallet.png'} />*/}
            {/*<img width={400} src={'/images/mfg-stack.png'} />*/}
            {/*{(() => {*/}
            {/*  console.log('xxx', {*/}
            {/*    positions,*/}
            {/*    incentives,*/}
            {/*    adsincentives,*/}
            {/*    currentAdsIncentiveId,*/}
            {/*    globalData,*/}
            {/*    unigraphData,*/}
            {/*  });*/}
            {/*})()}*/}
            {!adsincentives.length ? null : (
              <AdsLiquidityCard
                startConnectingWallet={startConnectingWallet}
                {...{ position: {}, history: {} }}
                incentives={adsincentives}
                globalData={globalData}
                unigraphData={unigraphData}
                currentIncentiveId={currentAdsIncentiveId}
              />
            )}
            <Box
              mt={2}
              display='none'
              // display='flex'
              justifyContent='center'
              alignItems='center'
            >
              <Button
                color='secondary'
                variant='contained'
                onClick={startConnectingWallet}
              >
                Connect Wallet
              </Button>
            </Box>
          </>
        ) : (
          <>
            {loading && <LinearProgress color='secondary' />}

            {/*<Box m={0} mb={2} className='flex flex-grow justify-space'>*/}
            {/*  <Box>*/}
            {/*    <Box display={'none'}>*/}
            {/*      <Typography>*/}
            {/*        You have {positions.length} {token0Symbol}-{token1Symbol}{' '}*/}
            {/*        liquidity positions.*/}
            {/*      </Typography>*/}
            {/*      <Typography>MFG Balance {balance?.toString()}</Typography>*/}
            {/*    </Box>*/}
            {/*    <Box>*/}
            {/*      <Typography variant='caption'>*/}
            {/*        Get {!positions.length ? 'some' : 'more'} by providing*/}
            {/*        liquidity to the {token0Symbol}-{token1Symbol} Pool over{' '}*/}
            {/*        <a*/}
            {/*          href={`https://app.uniswap.org/#/add/${[*/}
            {/*            token1Address,*/}
            {/*            token0Address,*/}
            {/*            3000,*/}
            {/*          ].join('/')}`}*/}
            {/*          target='_blank'*/}
            {/*          rel='noopener noreferrer'*/}
            {/*        >*/}
            {/*          here*/}
            {/*        </a>*/}
            {/*        .*/}
            {/*      </Typography>*/}
            {/*    </Box>*/}
            {/*    <div>Reward ID: {currentIncentiveId}</div>*/}
            {/*  </Box>*/}
            {/*</Box>*/}

            {/*<Box*/}
            {/*  */}
            {/*  m={0}*/}
            {/*  mt={0}*/}
            {/*  mb={5}*/}
            {/*  className='flex flex-grow justify-space'*/}
            {/*  // display={'none'}*/}
            {/*>*/}
            {/*  <FormControl>*/}
            {/*    {!currentIncentiveId ? null : (*/}
            {/*      <>*/}
            {/*        <InputLabel id='incentive-label' shrink>*/}
            {/*          MFG Reward campaign*/}
            {/*        </InputLabel>*/}
            {/*        <Select*/}
            {/*          labelId='incentive-label'*/}
            {/*          id='incentive'*/}
            {/*          value={currentIncentiveId}*/}
            {/*          displayEmpty*/}
            {/*          onChange={(e) => {*/}
            {/*            setCurrentIncentiveId(e.target.value as string);*/}
            {/*          }}*/}
            {/*        >*/}
            {/*          <MenuItem value='' disabled>*/}
            {/*            All reward program by MFG*/}
            {/*          </MenuItem>*/}
            {/*          {incentives.map((incentive) => (*/}
            {/*            <MenuItem value={incentive.id} key={incentive.id}>*/}
            {/*              {`*/}
            {/*              Reward*/}
            {/*              ${formatUnits(*/}
            {/*                incentive.reward.toString(),*/}
            {/*                18*/}
            {/*              )} MFG ${timeAgo(incentive.key.endTime)} */}
            {/*              (${formatTimestamp(incentive.key.startTime)} to */}
            {/*              ${formatTimestamp(incentive.key.endTime)})*/}
            {/*              ${incentive.ended ? 'ENDED' : ''}*/}
            {/*              `}*/}
            {/*            </MenuItem>*/}
            {/*          ))}*/}
            {/*        </Select>*/}
            {/*      </>*/}
            {/*    )}*/}
            {/*    <FormHelperText>*/}
            {/*      Selected campaign {currentIncentiveId}*/}
            {/*    </FormHelperText>*/}
            {/*  </FormControl>*/}
            {/*  <Box display={'none'}>*/}
            {/*    <img width={120} src={'/images/univ3_logo.png'} />*/}
            {/*  </Box>*/}
            {/*</Box>*/}
            {/*<ClaimAvailableReward />*/}
            {!positions.length
              ? null
              : currentIncentiveId &&
                positions.map((position) => (
                  <LiquidityCard
                    key={position.tokenId}
                    globalData={globalData}
                    {...{ position, history }}
                    incentives={incentives}
                    unigraphData={unigraphData}
                    currentIncentiveId={currentIncentiveId}
                  />
                ))}

            {!adsincentives.length || positions.length ? null : (
              <AdsLiquidityCard
                startConnectingWallet={startConnectingWallet}
                {...{ position: {}, history: {} }}
                incentives={adsincentives}
                globalData={globalData}
                unigraphData={unigraphData}
                buyLPLink={{
                  // text: `Providing liquidity to the ${token0Symbol}-${token1Symbol} Pool`,
                  text: `Provide Liquidity`,
                  link: getLPlink,
                  component: () => (
                    <>
                      <Alert className={classes.depositLp} severity='info'>
                        Get {!positions.length ? 'some' : 'more'} by providing
                        liquidity to the {token0Symbol}-{token1Symbol} Pool over{' '}
                        <a
                          href={getLPlink}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          here
                        </a>
                        .
                      </Alert>
                    </>
                  ),
                }}
                currentIncentiveId={currentAdsIncentiveId}
              />
            )}

            {/*{!positions.length ? null : (*/}
            {/*  <Box display={'none'} mt={2}>*/}
            {/*    <Table aria-label='Loans' size={'small'}>*/}
            {/*      <TableHead>*/}
            {/*        <TableRow>*/}
            {/*          <TableCell>Pool ID</TableCell>*/}
            {/*          <TableCell>Rewards</TableCell>*/}
            {/*          <TableCell*/}
            {/*            align='right'*/}
            {/*            className={classes.depositButtonCell}*/}
            {/*          ></TableCell>*/}
            {/*          <TableCell*/}
            {/*            align='right'*/}
            {/*            className={classes.depositButtonCell}*/}
            {/*          ></TableCell>*/}
            {/*        </TableRow>*/}
            {/*      </TableHead>*/}
            {/*      <TableBody>*/}
            {/*        {positions.map((position) => (*/}
            {/*          <LiquidityPositionTableRow*/}
            {/*            key={position.tokenId}*/}
            {/*            {...{ position, history }}*/}
            {/*          />*/}
            {/*        ))}*/}
            {/*      </TableBody>*/}
            {/*    </Table>*/}
            {/*  </Box>*/}
            {/*)}*/}

            {/*<Box*/}
            {/*  mt={5}*/}
            {/*  display='flex'*/}
            {/*  justifyContent='center'*/}
            {/*  alignItems='center'*/}
            {/*>*/}
            {/*  <Alert className={classes.depositLp} severity='info'>*/}
            {/*    Get {!positions.length ? 'some' : 'more'} by providing liquidity*/}
            {/*    to the {token0Symbol}-{token1Symbol} Pool over{' '}*/}
            {/*    <a*/}
            {/*      href={getLPlink}*/}
            {/*      target='_blank'*/}
            {/*      rel='noopener noreferrer'*/}
            {/*    >*/}
            {/*      here*/}
            {/*    </a>*/}
            {/*    .*/}
            {/*  </Alert>*/}
            {/*</Box>*/}
          </>
        )}
        {/*<Fab className={classes.fab} color='primary' aria-label='add'>*/}
        {/*  <AddIcon />*/}
        {/*</Fab>*/}
      </Box>
    </>
  );
};

// const ClaimAvailableReward: FC = () => {
//   const classes = useStyles();
//   const { stakingRewardsContract } = useContracts();
//   const {
//     currentIncentiveRewardTokenSymbol,
//     currentIncentiveRewardTokenDecimals,
//     currentIncentive,
//   } = useData();
//   const { address } = useWallet();
//   const { tx } = useNotifications();
//
//   const [reward, setReward] = useState(ethers.BigNumber.from(0));
//   const [isClaiming, setIsClaiming] = useState(false);
//
//   useEffect(() => {
//     if (!(stakingRewardsContract && currentIncentive && address)) return;
//
//     let isMounted = true;
//     const unsubs = [
//       () => {
//         isMounted = false;
//       },
//     ];
//
//     const load = async () => {
//       const reward = await stakingRewardsContract.rewards(
//         currentIncentive.key.rewardToken,
//         address
//       );
//       if (isMounted) setReward(reward);
//     };
//
//     const subscribe = () => {
//       const tokenUnstakedEvent = stakingRewardsContract.filters.TokenUnstaked();
//       stakingRewardsContract.on(tokenUnstakedEvent, load);
//       unsubs.push(() => {
//         stakingRewardsContract.off(tokenUnstakedEvent, load);
//       });
//
//       const rewardClaimedEvent = stakingRewardsContract.filters.RewardClaimed();
//       stakingRewardsContract.on(rewardClaimedEvent, load);
//       unsubs.push(() => {
//         stakingRewardsContract.off(rewardClaimedEvent, load);
//       });
//     };
//
//     load();
//     subscribe();
//
//     return () => {
//       unsubs.map((u) => u());
//     };
//   }, [stakingRewardsContract, currentIncentive, address]);
//
//   const claim = async () => {
//     if (!(stakingRewardsContract && currentIncentive)) return;
//
//     try {
//       setIsClaiming(true);
//       const reward = await stakingRewardsContract.rewards(
//         currentIncentive.key.rewardToken,
//         address
//       );
//       await tx('Claiming...', 'Claimed!', () =>
//         stakingRewardsContract.claimReward(
//           currentIncentive.key.rewardToken,
//           address,
//           reward
//         )
//       );
//     } catch (e) {
//       console.warn(e);
//     } finally {
//       setIsClaiming(false);
//     }
//   };
//
//   return (
//     <Box className='flex items-center' mt={5}>
//       <Box mr={1}>YOUR REWARDS:</Box>{' '}
//       <Box mr={2}>
//         {formatUnits(reward, currentIncentiveRewardTokenDecimals)}{' '}
//         {currentIncentiveRewardTokenSymbol}
//       </Box>
//       {/*{reward.isZero() ? '(Please stake your MFG LP)' : ''}*/}
//       <Button
//         color='secondary'
//         variant='contained'
//         onClick={claim}
//         className={classes.depositButton}
//         disabled={isClaiming || reward.isZero()}
//       >
//         {isClaiming ? 'Claiming...' : 'Claim'}
//       </Button>
//     </Box>
//   );
// };

export default withRouter(Stake);
