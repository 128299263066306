import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Typography,
  Toolbar,
  Button,
  SvgIcon,
  Avatar,
} from '@material-ui/core';
import { useWallet } from 'contexts/wallet';
import { APP_NAME } from 'config';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';
import {
  // BrowserView,
  // MobileView,
  // isBrowser,
  isMobile,
} from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  container: {
    boxShadow: 'none',
  },
  title: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  logo: {
    height: 55,
    [theme.breakpoints.down('xs')]: {
      height: 45,
    },
  },
  account: {
    marginRight: 10,
    // [theme.breakpoints.down('xs')]: {
    //   display: 'none',
    // },
  },
}));

const Header: FC = () => {
  const classes = useStyles();
  const { address, network, startConnecting, disconnect } = useWallet();

  const shortAddress =
    address && `${address.slice(0, 6)}....${address.slice(-4)}`;

  let logo = '/wallets/metamask.svg';

  if (isMobile) {
    logo = '/wallets/wallet-connect.svg';
  }

  return (
    <AppBar position='fixed' color='transparent' className={classes.container}>
      <Toolbar color='inherit'>
        <Typography variant='h6' className={'flex flex-grow'}>
          <Link href='https://smartmfg.io' underline='none'>
            <img
              className={classes.logo}
              src={'/images/logo.svg'}
              alt={'MFG - SmartMFG LP Rewards Programs'}
              style={{ marginRight: 10, marginTop: 10 }}
            />
          </Link>
          {/*<div className={'flex items-center'}>{APP_NAME}</div>*/}
        </Typography>

        {address ? (
          <>
            &nbsp;
            <div className={classes.account}>
              {/*{shortAddress} ({network})*/}
              <Chip
                size={'medium'}
                avatar={<Avatar src={'/images/icon.png'}></Avatar>}
                label={`${shortAddress} (${network})`}
                onDelete={disconnect}
                variant='outlined'
              />
            </div>
            {/*<Button color='secondary' onClick={disconnect}>*/}
            {/*  Disconnect*/}
            {/*</Button>*/}
          </>
        ) : (
          <Chip
            size={'medium'}
            avatar={<Avatar src={logo}></Avatar>}
            label='CONNECT WALLET'
            clickable
            onClick={startConnecting}
            // color='secondary'
          />
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
