import { FC, useCallback, useState, useEffect } from 'react';
import * as ethers from 'ethers';
import { Button, Box, Chip } from '@material-ui/core';

// import { TableRow, TableCell, Button, Box, Tooltip } from '@material-ui/core';

// import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
// import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
// import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import CountUp from 'react-countup';
// import { useCountUp } from 'react-countup';

// import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
// import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
// import InfoIcon from '@material-ui/icons/Info';

import { formatUnits, toBigNumber, toFixed } from 'utils/big-number';
// import BigNumber from 'bignumber.js';
import { useWallet } from 'contexts/wallet';
// import useTokenInfo from 'hooks/useTokenInfo';
// import { LiquidityPosition } from '../../utils/types';
import { useContracts } from '../../contexts/contracts';
// import { sizing } from '@material-ui/system';

import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import clsx from 'clsx';
import Card from '@material-ui/core/Card';
// import CardHeader from '@material-ui/core/CardHeader';
// import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
// import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
// import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// import { red } from '@material-ui/core/colors';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import ShareIcon from '@material-ui/icons/Share';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import { timeAgo, formatTimestamp } from 'utils/time';
import { useData } from '../../contexts/data';
import { useNotifications } from '../../contexts/notifications';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      // backgroundColor: theme.palette.info.dark,
      // opacity: 0.8,
      padding: 12,
      [theme.breakpoints.down('sm')]: {
        padding: 10,
      },
      // maxWidth: 345
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      // backgroundColor: red[500],
    },
    title: {
      fontSize: 30,
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
      // [theme.breakpoints.down('xs')]: {
      //   fontSize: 15,
      // },
    },
    maxButton: {
      height: 35,
    },
    // depositButtonCell: {
    //   width: '100%',
    //   // padding: 5,
    // },
    depositButton: {
      // backgroundColor: theme.palette.secondary.dark,
      width: '100%',
      // width: 100,
    },
    getLpButton: {
      // backgroundColor: theme.palette.secondary.dark,
      color: '#000000 !important',
      width: '100%',
      // width: 100,
    },
    depositWrapper: {
      // backgroundColor: theme.palette.secondary.dark,
      // padding: 10,
      // width: 100,
    },
    details: {
      width: '100%',
      backgroundColor: 'transparent',
      boxShadow: '0 0 0',
    },
    content: {
      paddingBottom: 15,
      textAlign: 'center',
      // padding: 10,
      // width: 100,
    },
    cover: {
      width: 100,
    },
    description: {
      color: 'rgba(255, 255, 255, 0.7)',
      fontWeight: 300,
    },

    largeIcon: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      [theme.breakpoints.down('sm')]: {
        width: '40px',
        height: '40px',
      },
      // [theme.breakpoints.down('xs')]: {
      //   width: '32px',
      //   height: '32px',
      // },
    },

    rightIcon: {
      marginLeft: '32px',
      padding: 3,
      [theme.breakpoints.down('sm')]: {
        marginLeft: '34px',
      },
      // [theme.breakpoints.down('xs')]: {
      //   marginLeft: '28px',
      // },
    },
  })
);

const AdsLiquidityCard: FC<{
  startConnectingWallet: any;
  position: any;
  globalData: any;
  unigraphData: any;
  history: any;
  incentives: any;
  currentIncentiveId: any;
  buyLPLink?: any;
}> = ({
  position,
  history,
  incentives,
  currentIncentiveId,
  buyLPLink,
  startConnectingWallet,
  globalData,
  unigraphData,
}) => {
  const classes = useStyles();
  const { address, network } = useWallet();
  const { token0Decimals } = useContracts();
  const {
    token0Address,
    token1Address,
    token0Symbol,
    token1Symbol,
  } = useContracts();

  const { stakingRewardsContract } = useContracts();
  const {
    currentIncentiveRewardTokenSymbol,
    currentIncentiveRewardTokenDecimals,
    currentIncentive,
  } = useData();
  const { tx } = useNotifications();

  const [reward, setReward] = useState(ethers.BigNumber.from(0));
  const [isClaiming, setIsClaiming] = useState(false);

  useEffect(() => {
    if (!(stakingRewardsContract && currentIncentive && address)) return;

    let isMounted = true;
    const unsubs = [
      () => {
        isMounted = false;
      },
    ];

    const load = async () => {
      const reward = await stakingRewardsContract.rewards(
        currentIncentive.key.rewardToken,
        address
      );
      if (isMounted) setReward(reward);
    };

    const subscribe = () => {
      const tokenUnstakedEvent = stakingRewardsContract.filters.TokenUnstaked();
      stakingRewardsContract.on(tokenUnstakedEvent, load);
      unsubs.push(() => {
        stakingRewardsContract.off(tokenUnstakedEvent, load);
      });

      const rewardClaimedEvent = stakingRewardsContract.filters.RewardClaimed();
      stakingRewardsContract.on(rewardClaimedEvent, load);
      unsubs.push(() => {
        stakingRewardsContract.off(rewardClaimedEvent, load);
      });
    };

    load();
    subscribe();

    return () => {
      unsubs.map((u) => u());
    };
  }, [stakingRewardsContract, currentIncentive, address]);

  const claim = async () => {
    if (!(stakingRewardsContract && currentIncentive)) return;

    try {
      setIsClaiming(true);
      const reward = await stakingRewardsContract.rewards(
        currentIncentive.key.rewardToken,
        address
      );
      await tx('Claiming...', 'Claimed!', () =>
        stakingRewardsContract.claimReward(
          currentIncentive.key.rewardToken,
          address,
          reward
        )
      );
    } catch (e) {
      console.warn(e);
    } finally {
      setIsClaiming(false);
    }
  };
  //xxx

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const stake = useCallback(async () => {
    history.push(`/stake/${position.tokenId}`);
  }, [position.tokenId, history]);

  const unstake = useCallback(async () => {
    history.push(`/unstake/${position.tokenId}`);
  }, [position.tokenId, history]);

  const withdraw = useCallback(async () => {
    history.push(`/withdraw/${position.tokenId}`);
  }, [position.tokenId, history]);

  const incentive = incentives.filter(
    (i: any) => i.id === currentIncentiveId
  )[0];

  const totalRewards = Number(
    formatUnits(incentive.reward.toString(), 18, 0).replaceAll(',', '')
  );

  // console.log('incentive', {
  //   incentive,
  //   position,
  //   totalRewards,
  //   teo: formatUnits(incentive.reward.toString(), 18, 0).replaceAll(',', ''),
  // });

  return (
    <>
      <Box p={0} mt={5} width={1}>
        <Card className={classes.root}>
          <CardContent>
            <Box className='flex flex-grow justify-space'>
              <Box width={'90px'}>
                <div
                  style={{
                    position: 'relative',
                  }}
                >
                  <Avatar
                    className={`${classes.largeIcon} ${classes.rightIcon}`}
                    style={{
                      position: 'absolute',
                      zIndex: 10,
                      background: 'rgb(0,0,0,0.5)',
                    }}
                    src={'/images/icon.png'}
                  ></Avatar>
                  <Avatar
                    className={classes.largeIcon}
                    style={{
                      position: 'absolute',
                    }}
                    src={'https://i.imgur.com/oB4WqwA.png'}
                  ></Avatar>
                </div>
              </Box>
              <Box>
                <Typography
                  align={'center'}
                  variant='h3'
                  component='h2'
                  className={classes.title}
                  color='textPrimary'
                  gutterBottom
                >
                  Uniswap V3 {globalData && globalData.token0}-
                  {globalData && globalData.token1} Liquidity Program
                </Typography>
                <Typography
                  align={'center'}
                  variant='subtitle1'
                  component='h3'
                  className={classes.description}
                >
                  <Chip label='EXPIRED' style={{ backgroundColor: 'red' }} />
                  {`  
                
                ${formatTimestamp(incentive.key.startTime)} - 
                ${formatTimestamp(incentive.key.endTime)}
                ${incentive.ended ? 'ENDED' : ''}
                `}
                </Typography>
                <Typography
                  align={'center'}
                  variant='subtitle2'
                  component='h4'
                  // className={classes.description}
                >
                  Reward Distribution ={' '}
                  <CountUp
                    separator={','}
                    // prefix=''
                    // redraw={true}
                    duration={4}
                    preserveValue={true}
                    suffix=' MFG'
                    end={totalRewards}
                  />{' '}
                  (
                  <CountUp
                    separator={','}
                    prefix='$'
                    // redraw={true}
                    duration={2}
                    preserveValue={true}
                    // suffix=' USD'
                    end={globalData && globalData.total_mfg_reward_usd}
                  />
                  )
                </Typography>
                {/*{globalData && globalData.total_mfg_reward_usd}*/}
              </Box>
              <Box>
                <Avatar
                  className={classes.largeIcon}
                  src={'https://i.imgur.com/axCsOTc.png'}
                ></Avatar>
              </Box>
            </Box>
          </CardContent>

          <CardContent>
            <Typography variant='body2' color='textSecondary' component='p'>
              <Box className='flex flex-grow'>
                <Box mr={2} width={1 / 3}>
                  <Card className={classes.details}>
                    <div>
                      <CardContent className={classes.content}>
                        <Typography component='h5' variant='h5'>
                          <CountUp
                            separator={','}
                            prefix='$'
                            // redraw={true}
                            duration={1}
                            preserveValue={true}
                            // suffix=' MFG'
                            end={Number(
                              parseFloat(
                                unigraphData.totalValueLockedUSD
                              ).toFixed(0)
                            )}
                          />
                        </Typography>
                        <Typography variant='subtitle1' color='textSecondary'>
                          TVL
                        </Typography>
                      </CardContent>
                    </div>
                    {/*  <CardMedia*/}
                    {/*    className={classes.cover}*/}
                    {/*    image='/images/icon.png'*/}
                    {/*    title='Live from space album cover'*/}
                    {/*  />*/}
                  </Card>
                </Box>
                <Box width={1 / 3} mr={2}>
                  <Card className={classes.details}>
                    <div>
                      <CardContent className={classes.content}>
                        <Typography component='h5' variant='h5'>
                          {/*<div ref={countUpRef} />*/}
                          0.0
                        </Typography>
                        <Typography variant='subtitle1' color='textSecondary'>
                          Pending rewards
                        </Typography>
                      </CardContent>
                    </div>
                    {/*  <CardMedia*/}
                    {/*    className={classes.cover}*/}
                    {/*    image='/static/images/cards/live-from-space.jpg'*/}
                    {/*    title='Live from space album cover'*/}
                    {/*  />*/}
                  </Card>
                </Box>
                <Box width={1 / 3}>
                  <Card className={classes.details}>
                    <div>
                      <CardContent className={classes.content}>
                        <Typography component='h5' variant='h5'>
                          0.0
                        </Typography>
                        <Typography variant='subtitle1' color='textSecondary'>
                          {`Accrued rewards`}
                        </Typography>
                      </CardContent>
                    </div>
                    {/*  <CardMedia*/}
                    {/*    className={classes.cover}*/}
                    {/*    image='/static/images/cards/live-from-space.jpg'*/}
                    {/*    title='Live from space album cover'*/}
                    {/*  />*/}
                  </Card>
                </Box>
              </Box>
            </Typography>
          </CardContent>
          <CardActions className={classes.depositWrapper}>
            {buyLPLink ? (
              <Box pl={5} pr={5} width={1}>
                <Link
                  href={buyLPLink.link}
                  target='_blank'
                  rel='noreferrer'
                  underline='none'
                >
                  <Button
                    color={'secondary'}
                    size={'medium'}
                    className={classes.getLpButton}
                    variant={'contained'}
                  >
                    {buyLPLink.text}
                  </Button>
                </Link>
              </Box>
            ) : (
              <>
                <Box pl={5} pr={5} width={1}>
                  <Button
                    color={'secondary'}
                    onClick={startConnectingWallet}
                    size={'medium'}
                    className={classes.depositButton}
                    variant={'contained'}
                  >
                    Connect Wallet
                  </Button>
                </Box>
              </>
            )}
            {/* Claim */}
            {/*<Box pl={5} pr={5} width={1 / 2}>*/}
            {/*  <Button*/}
            {/*    color='secondary'*/}
            {/*    variant='contained'*/}
            {/*    onClick={startConnectingWallet}*/}
            {/*    className={classes.depositButton}*/}
            {/*    disabled={isClaiming || reward.isZero()}*/}
            {/*  >*/}
            {/*    {isClaiming ? 'Claiming...' : 'Claim Accrued rewards'}*/}
            {/*  </Button>*/}
            {/*</Box>*/}
          </CardActions>
        </Card>
      </Box>
    </>
  );
};

export default AdsLiquidityCard;
