import fetch from 'unfetch';
import qs from 'query-string';
// import { createClient } from 'urql';

export async function get(url: string, query?: Record<string, any>) {
  if (query) {
    url += '?' + qs.stringify(query);
  }
  return await (await fetch(url)).json();
}

//
//
// const APIURL = "https://api.thegraph.com/subgraphs/name/username/subgraphname";
//
// const tokensQuery = `
//   query {
//     tokens {
//       id
//       tokenID
//       contentURI
//       metadataURI
//     }
//   }
// `
//
// const client = createClient({
//   url: APIURL
// });
//
// const data = await client.query(tokensQuery).toPromise();

export function subgraph(subgraphUrl: string) {
  return async function (query: string, variables: Record<string, any>) {
    const res = await fetch(subgraphUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ query, variables }),
    });
    const { data } = await res.json();
    return data;
  };
}

// export function subgraph(subgraphUrl: string) {
//   return async function (query: string, variables: Record<string, any>) {
//     const res = await fetch(subgraphUrl, {
//       method: 'POST',
//       body: JSON.stringify({ query, variables }),
//     });
//     const { data } = await res.json();
//     return data;
//   };
// }
// {"query":"query {\n          incentives(orderBy: endTime, orderDirection: desc) {\n            id\n            rewardToken\n          }\n        }","variables":null}
// {"query":"query {\n          incentives(orderBy: endTime, orderDirection: desc) {\n            id\n            rewardToken\n            pool\n            startTime\n            endTime\n            refundee\n            reward\n            ended\n          }\n        }","variables":{}}
