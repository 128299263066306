import {
  FC,
  useState,
  useContext,
  useMemo,
  useEffect,
  createContext,
  ReactNode,
} from 'react';
import _flatten from 'lodash/flatten';
import _orderBy from 'lodash/orderBy';
import base64 from 'base-64';

import { useWallet } from 'contexts/wallet';
import { useContracts } from 'contexts/contracts';
import useTokenInfo from 'hooks/useTokenInfo';
import { Incentive, LiquidityPosition } from 'utils/types';
import { formatUnits, toBigNumber, toFixed } from 'utils/big-number';
import * as request from 'utils/request';
import {
  SUBGRAPHS,
  TOKEN_0_ADDRESS,
  TOKEN_1_ADDRESS,
  INCENTIVE,
  POOL,
  NETWORK_RINKEBY,
  NETWORK_MAINNET,
  COINGECKO,
} from 'config';

const BPromise = require('bluebird');

// import { useLocation } from 'react-router-dom';

const DataContext = createContext<{
  positions: LiquidityPosition[];
  incentives: Incentive[];
  adsincentives: Incentive[];
  balance: string | null;
  currentIncentiveId: string | null;
  globalData: any | null;
  loading: any | null;
  currentAdsIncentiveId: string | null;
  currentIncentive: Incentive | null;
  unigraphData: any;
  setCurrentIncentiveId: (id: string) => void;
  setCurrentAdsIncentiveId: (id: string) => void;
  currentIncentiveRewardTokenSymbol: string | null;
  currentIncentiveRewardTokenDecimals: number | null;
} | null>(null);

function getParameterByName(name: any, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function sleep(ms: any) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

let cachedIndex = {};

export const DataProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const {
    stakingRewardsContract,
    nftManagerPositionsContract,
    erc20Contract,
    token0Decimals,
  } = useContracts();
  const { network, address } = useWallet();

  const token0Address = !network ? '' : TOKEN_0_ADDRESS[network];
  const token1Address = !network ? '' : TOKEN_1_ADDRESS[network];

  const [loading, setLoading] = useState<any | null>(null);
  const [positions, setPositions] = useState<LiquidityPosition[]>([]);
  const [incentives, setIncentiveIds] = useState<Incentive[]>([]);
  const [globalData, setGlobalData] = useState<any>([]);
  const [unigraphData, setUnigraphData] = useState<any>([]);
  const [adsincentives, setAdsIncentiveIds] = useState<Incentive[]>([]);
  const [balance, setBalance] = useState<any>([]);
  const [currentIncentiveId, setCurrentIncentiveId] = useState<string | null>(
    null
  );
  const [currentAdsIncentiveId, setCurrentAdsIncentiveId] = useState<
    string | null
  >(null);

  let force = network === NETWORK_MAINNET ? getParameterByName('campaign') : '';

  const currentIncentive = useMemo(
    () =>
      !currentIncentiveId
        ? null
        : incentives.find((incentive) => incentive.id === currentIncentiveId) ??
          null,
    [currentIncentiveId, incentives]
  );

  const {
    decimals: currentIncentiveRewardTokenDecimals,
    symbol: currentIncentiveRewardTokenSymbol,
  } = useTokenInfo(currentIncentive?.key.rewardToken ?? null);
  // const {
  //   decimals: currentMFGDecimals,
  //   symbol: currentMFGSymbol,
  //   balance: currentMFGBalance,
  // } = useTokenInfo(address ?? null);
  //
  // console.log('currentMFGBalance', {
  //   currentMFGBalance,
  // });
  // load MFG balance
  // load owned and transfered positions
  // useEffect(() => {
  //   if (!(erc20Contract && address)) return;
  //
  //   const getBalance = async (owner: string) => {
  //     const MFG_BALANCE = await erc20Contract.balanceOf(owner);
  //     console.log('owner', {
  //       owner,
  //       MFG_BALANCE: formatUnits(MFG_BALANCE, token0Decimals),
  //     });
  //     return MFG_BALANCE;
  //   };
  //
  //   const load = async () => {
  //     const owners: string[] = [address, address, address];
  //     const balance = await Promise.all(owners.map(getBalance));
  //
  //     setBalance(formatUnits(balance[2], token0Decimals));
  //   };
  //
  //   load();
  // }, [erc20Contract, address]);

  // load GlobalData
  useEffect(() => {
    let inetwork = NETWORK_MAINNET;
    const load = async () => {
      let mfg_price = await request.get(COINGECKO[inetwork]);

      let incentiveNetwork = INCENTIVE[inetwork + force] || INCENTIVE[inetwork];
      // if (inetwork === NETWORK_MAINNET) inetwork = inetwork + force;
      // let reward = location.includes('mfg-mainnet-test')
      //   ? INCENTIVE[inetwork].reward
      //   : INCENTIVE[inetwork].reward;

      const TOTAL_REWARDS = Number(
        formatUnits(incentiveNetwork.reward, 18, 0).replaceAll(',', '')
      );

      let mfg_price_usd = mfg_price[TOKEN_0_ADDRESS[inetwork]].usd;

      setGlobalData({
        total_mfg_reward_usd: mfg_price_usd * TOTAL_REWARDS,
        token0: 'MFG',
        token1: 'ETH',
        force,
        network,
      });
    };

    load();

    const interval = setInterval(() => {
      // console.log('This will run 3 second!');
      load();
    }, 40000);

    return () => {
      clearInterval(interval);
    };
  }, [network]);

  //  Set unigraphData
  useEffect(() => {
    let inetwork = NETWORK_MAINNET;
    const load = async () => {
      // let mfg_price = await request.get(COINGECKO[inetwork]);
      //
      // const TOTAL_REWARDS = Number(
      //   formatUnits(INCENTIVE[inetwork].reward, 18, 0).replaceAll(',', '')
      // );
      //
      // let mfg_price_usd = mfg_price[TOKEN_0_ADDRESS[inetwork]].usd;
      // setGlobalData({
      //   total_mfg_reward_usd: mfg_price_usd * TOTAL_REWARDS,
      //   token0: 'MFG',
      //   token1: 'ETH',
      // });

      const subgraph = request.subgraph(SUBGRAPHS[inetwork])!;
      const data = await subgraph(
        `
        query {
          pools( where: {id_in: ["0x3c8e1997d9f93c989d4d7660b6ca52c451ad7554"]}
              
                  orderBy: totalValueLockedUSD
                      orderDirection: desc
                          subgraphError: allow
                            ) {
                                id
                                    feeTier
                                        liquidity
                                            sqrtPrice
                                                tick
                                                    token0 {
                                                          id
                                                                symbol
name
decimals
derivedETH
__typename
}
token1 {
id
symbol
name
decimals
derivedETH
__typename
}
token0Price
token1Price
volumeUSD
txCount
totalValueLockedToken0
totalValueLockedToken1
totalValueLockedUSD
__typename
}
          
        }

        `,
        {}
      );

      // console.log('data', {
      //   data,
      // });
      setUnigraphData(data.pools[0]);
    };

    load();

    const interval = setInterval(() => {
      // console.log('This will run 3 second!');
      load();
    }, 20000);

    return () => {
      clearInterval(interval);
    };
  }, [network]);

  // load ads incentives
  useEffect(() => {
    // if (!network) return;

    let inetwork = network || NETWORK_MAINNET;
    const load = async () => {
      let incentiveNetwork = INCENTIVE[inetwork + force] || INCENTIVE[inetwork];
      setAdsIncentiveIds([
        {
          id: incentiveNetwork.id,
          reward: toBigNumber(incentiveNetwork.reward),
          ended: incentiveNetwork.ended,
          key: {
            rewardToken: token0Address,
            pool: POOL[inetwork],
            startTime: Number(incentiveNetwork.startTime),
            endTime: Number(incentiveNetwork.endTime),
            refundee: incentiveNetwork.refundee,
          },
        },
      ]);

      // console.log('INCENTIVE[network]0', incentives[0]?.id);
      // console.log('INCENTIVE[network]', INCENTIVE[inetwork]);
      setCurrentAdsIncentiveId(incentiveNetwork.id);
    };

    load();
  }, [network]);
  // load incentives
  useEffect(() => {
    // console.log('network', {
    //   network,
    // });
    if (!network) return;

    const inetwork = network;
    const load = async () => {
      // set incentive
      let incentiveNetwork = INCENTIVE[inetwork + force] || INCENTIVE[inetwork];
      setIncentiveIds([
        {
          id: incentiveNetwork.id,
          reward: toBigNumber(incentiveNetwork.reward),
          ended: incentiveNetwork.ended,
          key: {
            rewardToken: token0Address,
            pool: POOL[inetwork],
            startTime: Number(incentiveNetwork.startTime),
            endTime: Number(incentiveNetwork.endTime),
            refundee: incentiveNetwork.refundee,
          },
        },
      ]);

      // console.log('INCENTIVE[network]0', incentives[0]?.id);
      // console.log('INCENTIVE[network]', INCENTIVE[inetwork]);
      setCurrentIncentiveId(incentiveNetwork.id);
    };

    load();
  }, [network]);

  // load owned and transfered positions
  useEffect(() => {
    if (
      !(
        nftManagerPositionsContract &&
        stakingRewardsContract &&
        address &&
        currentIncentiveId &&
        currentIncentive
      )
    ) {
      // console.log('owned', {
      //   nftManagerPositionsContract,
      //   stakingRewardsContract,
      //   address,
      //   currentIncentiveId,
      //   currentIncentive,
      // });
      return;
    }

    if (network === 'mainnet') return;

    let isMounted = true;
    const unsubs = [
      () => {
        isMounted = false;
      },
    ];

    const loadPositions = async (owner: string) => {
      // console.log('xxx loadPositions', {
      //   owner,
      // });
      setLoading(true);
      const noOfPositions = await nftManagerPositionsContract.balanceOf(owner);
      // console.log('xxx noOfPositions', {
      //   noOfPositions: noOfPositions.toNumber(),
      //   owner,
      // });
      const positions = await BPromise.map(
        new Array(noOfPositions.toNumber()).fill(0),
        async (_: any, index: any) => await loadPosition(owner, index),
        { concurrency: 20 }
      );
      // 20
      // const positions = await BPromise.all(
      //   // new Array(noOfPositions.toNumber())
      //   //   .fill(0)
      //   //   .map((_, index) => loadPosition(owner, index)),
      //
      //   BPromise.map(
      //     new Array(noOfPositions.toNumber()).fill(0),
      //     (_: any, index: any) => loadPosition(owner, index),
      //     { concurrency: 1 }
      //   ),
      //
      //   { concurrency: 1 }
      // );
      const ownerPositions: LiquidityPosition[] = [];
      positions.forEach((position: any) => {
        if (position) {
          ownerPositions.push(position);
        }
      });
      // console.log('xxx ownerPositions', {
      //   positions,
      //   ownerPositions,
      // });
      return ownerPositions;
    };

    const loadPosition = async (
      owner: string,
      index: number
    ): Promise<LiquidityPosition | null> => {
      // if (index > 600) return null;
      // if (index < 500) return null;

      // if (index <= 100) return null;
      // console.log('xxx loadPosition0', {
      //   owner,
      //   index,
      // });

      const tokenId = await nftManagerPositionsContract.tokenOfOwnerByIndex(
        owner,
        index
      );

      // cachedIndex
      await sleep(400);
      // let tokenData = await nftManagerPositionsContract.tokenURI(tokenId);
      // tokenData = tokenData.split(',')[1];

      // console.log('xxx tokenId', {
      //   tokenId: tokenId.toString(),
      // });
      const {
        liquidity,
        token0,
        token1,
        fee,
        tickLower,
        tickUpper,
        feeGrowthInside0LastX128,
        feeGrowthInside1LastX128,
        tokensOwed0,
        tokensOwed1,
      } = await nftManagerPositionsContract.positions(tokenId);
      if (liquidity.isZero()) return null;
      await sleep(200);
      const position = await stakingRewardsContract.deposits(tokenId);

      // if (tokenId.toString() == '125576') {
      //   console.log('xxx match', {
      //     token: tokenId.toString(),
      //   });
      // }

      // console.log('xxx position owner', {
      //   tokenId: tokenId.toString(),
      //   owner,
      //   position_owner: position.owner,
      //   address,
      // });

      if (
        owner.toLowerCase().trim() !== address.toLowerCase().trim() &&
        position.owner.toLowerCase().trim() !== address.toLowerCase().trim()
      ) {
        return null;
      }

      let staked = false;
      let reward = toBigNumber(0);
      try {
        const rewardData = await stakingRewardsContract.getRewardInfo(
          currentIncentive.key,
          tokenId
        );
        await sleep(400);

        const [rewardNumber] = rewardData;
        const { secondsInsideX128 } = rewardData;

        // console.log('xxx rewardData', {
        //   rewardData,
        //   secondsInsideX128: secondsInsideX128.toString(),
        // });
        reward = toBigNumber(rewardNumber.toString());
        // console.log('mfg-reward', {
        //   key: currentIncentive.key,
        //   tokenId,
        // });
        // console.log('rewardNumber', rewardNumber);
        // console.log('rewardNumber xreward', toBigNumber(reward).toString());
        staked = true;
      } catch {}
      return {
        tokenId: Number(tokenId.toString()),
        owner,
        reward,
        rewardString: toBigNumber(reward).toString(),
        tokenData: '', // JSON.parse(base64.decode(tokenData)),
        staked,
        token0,
        token1,
        fee,
        tickLower,
        tickUpper,
        liquidity,
        feeGrowthInside0LastX128,
        feeGrowthInside1LastX128,
        tokensOwed0,
        tokensOwed1,
      };
    };

    const load = async () => {
      const owners: string[] = [address, stakingRewardsContract.address];
      const positions = await Promise.all(owners.map(loadPositions));

      // console.log('xxx load positions done', { positions });

      let allPositions = _flatten(positions);

      // console.log('xxx allPositions 0', {
      //   allPositions,
      // });

      let filteredPosition = allPositions.filter((position) => {
        // console.log('xxx position 1', {
        //   position,
        // });
        // console.log('xxx', {
        //   p0: position.token0,
        //   p1: position.token1,
        // });
        // console.log(
        //   'xxx position 1.1',
        //   `${position.token0.toLowerCase()}=${token0Address.toLowerCase()}}
        //   ${position.token1.toLowerCase()}=${token1Address.toLowerCase()}
        //   `
        // );
        return (
          position.token0.toLowerCase() === token0Address.toLowerCase() &&
          position.token1.toLowerCase() === token1Address.toLowerCase()
        );
      });

      let positionData = _orderBy(filteredPosition, 'tokenId');

      // console.log('xxx positionData 2', {
      //   filteredPosition,
      //   positionData,
      // });
      if (isMounted) {
        setLoading(false);
        setPositions(positionData);
      }
    };

    load();

    const interval = setInterval(() => {
      // console.log('This will run 3 second!');
      load();
    }, 500000);

    return () => {
      clearInterval(interval);
      unsubs.map((u) => u());
    };
  }, [
    nftManagerPositionsContract,
    stakingRewardsContract,
    address,
    currentIncentiveId,
    currentIncentive,
  ]);

  // load owned and transfered positions mainnet
  useEffect(() => {
    if (
      !(
        nftManagerPositionsContract &&
        stakingRewardsContract &&
        address &&
        currentIncentiveId &&
        currentIncentive
      )
    ) {
      return;
    }
    if (network !== 'mainnet') return;

    // console.log('start prod scan');

    let inetwork = NETWORK_MAINNET;

    let isMounted = true;
    const unsubs = [
      () => {
        isMounted = false;
      },
    ];

    const loadPositions = async (owner: string) => {
      // console.log('xxx loadPositions', {
      //   owner,
      // });
      setLoading(true);
      const noOfPositions = await nftManagerPositionsContract.balanceOf(owner);
      // console.log('xxx noOfPositions', {
      //   noOfPositions: noOfPositions.toNumber(),
      //   owner,
      // });
      const positions = await BPromise.map(
        new Array(noOfPositions.toNumber()).fill(0),
        async (_: any, index: any) => await loadPosition(owner, index),
        { concurrency: 50 }
      );
      // 20
      // const positions = await BPromise.all(
      //   // new Array(noOfPositions.toNumber())
      //   //   .fill(0)
      //   //   .map((_, index) => loadPosition(owner, index)),
      //
      //   BPromise.map(
      //     new Array(noOfPositions.toNumber()).fill(0),
      //     (_: any, index: any) => loadPosition(owner, index),
      //     { concurrency: 1 }
      //   ),
      //
      //   { concurrency: 1 }
      // );
      const ownerPositions: LiquidityPosition[] = [];
      positions.forEach((position: any) => {
        if (position) {
          ownerPositions.push(position);
        }
      });
      // console.log('xxx ownerPositions', {
      //   positions,
      //   ownerPositions,
      // });
      return ownerPositions;
    };

    const loadPosition = async (
      positionData: any,
      index: number
    ): Promise<LiquidityPosition | null> => {
      // if (index > 600) return null;
      // if (index < 500) return null;

      // if (index <= 100) return null;
      let tokenId = positionData.id;

      // console.log('positionData,', {
      //   positionData,
      // });
      // console.log('xxx loadPosition0', {
      //   tokenId,
      //   index,
      // });

      const {
        liquidity,
        token0,
        token1,
        fee,
        tickLower,
        tickUpper,
        feeGrowthInside0LastX128,
        feeGrowthInside1LastX128,
        tokensOwed0,
        tokensOwed1,
      } = await nftManagerPositionsContract.positions(tokenId);
      if (liquidity.isZero()) return null;
      await sleep(500);
      const position = await stakingRewardsContract.deposits(tokenId);

      if (
        positionData.owner.toLowerCase().trim() !==
          address.toLowerCase().trim() &&
        position.owner.toLowerCase().trim() !== address.toLowerCase().trim()
      ) {
        return null;
      }

      let staked = false;
      let reward = toBigNumber(0);
      try {
        const rewardData = await stakingRewardsContract.getRewardInfo(
          currentIncentive.key,
          tokenId
        );
        await sleep(500);

        const [rewardNumber] = rewardData;
        const { secondsInsideX128 } = rewardData;

        reward = toBigNumber(rewardNumber.toString());

        staked = true;
      } catch {}

      return {
        tokenId: Number(tokenId.toString()),
        owner: positionData.owner,
        reward,
        rewardString: toBigNumber(reward).toString(),
        tokenData: '', // JSON.parse(base64.decode(tokenData)),
        staked,
        token0,
        token1,
        fee,
        tickLower,
        tickUpper,
        liquidity,
        feeGrowthInside0LastX128,
        feeGrowthInside1LastX128,
        tokensOwed0,
        tokensOwed1,
      };
    };

    const load = async () => {
      setLoading(true);
      const subgraph = request.subgraph(SUBGRAPHS[inetwork])!;
      const data = await subgraph(
        `
        query {
           positions(where:{ owner_in: ["${address}", "0x1f98407aaB862CdDeF78Ed252D6f557aA5b0f00d"], pool_in: ["0x3c8e1997d9f93c989d4d7660b6ca52c451ad7554"] }){
    id,owner,    
  }
          
        }

        `,
        {}
      );

      // console.log('data', {
      //   data,
      // });

      let positions = await BPromise.map(
        data.positions,
        async (_: any, index: any) => await loadPosition(_, index),
        { concurrency: 10 }
      );

      positions = positions.filter(Boolean);
      // let filteredPosition = positions.filter((position: any) => {
      //   console.log('xxx position 1', {
      //     position,
      //   });
      //   // console.log('xxx', {
      //   //   p0: position.token0,
      //   //   p1: position.token1,
      //   // });
      //   // console.log(
      //   //   'xxx position 1.1',
      //   //   `${position.token0.toLowerCase()}=${token0Address.toLowerCase()}}
      //   //   ${position.token1.toLowerCase()}=${token1Address.toLowerCase()}
      //   //   `
      //   // );
      //   return position.owner.toLowerCase() === address.toLowerCase();
      // });

      let positionData = _orderBy(positions, 'tokenId');

      // console.log('positions', {
      //   positions,
      //   positionData,
      // });
      if (isMounted) {
        setLoading(false);
        setPositions(positionData);
      }
    };

    load();

    const interval = setInterval(() => {
      // console.log('This will run 3 second!');
      load();
    }, 120000);

    return () => {
      clearInterval(interval);
      unsubs.map((u) => u());
    };
  }, [
    nftManagerPositionsContract,
    stakingRewardsContract,
    address,
    currentIncentiveId,
    currentIncentive,
  ]);

  useEffect(() => {
    if (!(stakingRewardsContract && currentIncentiveId)) return;

    let isMounted = true;
    const unsubs = [
      () => {
        isMounted = false;
      },
    ];

    const updateStaked = (tokenId: number, incentiveId: string) => {
      if (incentiveId !== currentIncentiveId) return;
      if (isMounted) {
        setPositions((positions) =>
          positions.map((position) => {
            if (position.tokenId !== Number(tokenId.toString()))
              return position;
            position.staked = true;
            return position;
          })
        );
      }
    };

    const updateUnstaked = (tokenId: number, incentiveId: string) => {
      if (incentiveId !== currentIncentiveId) return;
      if (isMounted) {
        setPositions((positions) =>
          positions.map((position) => {
            if (position.tokenId !== Number(tokenId.toString()))
              return position;
            position.staked = false;
            return position;
          })
        );
      }
    };

    const subscribe = () => {
      const stakedEvent = stakingRewardsContract.filters.TokenStaked();
      const unstakedEvent = stakingRewardsContract.filters.TokenUnstaked();

      stakingRewardsContract.on(stakedEvent, updateStaked);
      stakingRewardsContract.on(unstakedEvent, updateUnstaked);

      unsubs.push(() => {
        stakingRewardsContract.off(stakedEvent, updateStaked);
      });
      unsubs.push(() => {
        stakingRewardsContract.off(unstakedEvent, updateUnstaked);
      });
    };

    subscribe();

    return () => {
      unsubs.map((u) => u());
    };
  }, [stakingRewardsContract, positions, currentIncentiveId]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     console.log('This will run every second!');
  //     load();
  //   }, 1000);
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <DataContext.Provider
      value={{
        globalData,
        loading,
        unigraphData,
        balance,
        positions,
        incentives,
        adsincentives,
        currentIncentiveId,
        currentAdsIncentiveId,
        currentIncentive,
        setCurrentIncentiveId,
        setCurrentAdsIncentiveId,
        currentIncentiveRewardTokenSymbol,
        currentIncentiveRewardTokenDecimals,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export function useData() {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('Missing Data context');
  }
  const {
    globalData,
    loading,
    unigraphData,
    positions,
    balance,
    incentives,
    adsincentives,
    currentIncentiveId,
    currentAdsIncentiveId,
    currentIncentive,
    setCurrentIncentiveId,
    setCurrentAdsIncentiveId,
    currentIncentiveRewardTokenSymbol,
    currentIncentiveRewardTokenDecimals,
  } = context;

  return {
    loading,
    globalData,
    unigraphData,
    positions,
    incentives,
    adsincentives,
    balance,
    setCurrentAdsIncentiveId,
    currentIncentiveId,
    currentAdsIncentiveId,
    currentIncentive,
    setCurrentIncentiveId,
    currentIncentiveRewardTokenSymbol,
    currentIncentiveRewardTokenDecimals,
  };
}
