export const BORDER_RADIUS = 5;
export const APP_NAME = 'Liquidity Mining Programs';
export const APP_HEADER = 'Earn Rewards by Providing Liquidity';

export const LG_BREAKPOINT = 'md';
export const SM_BREAKPOINT = 'sm';

export const IS_DEV = process.env.NODE_ENV === 'development';

export const CACHE_WALLET_KEY = 'wallet';

export const NETWORK_MAINNET = 'mainnet';
export const NETWORK_RINKEBY = 'rinkeby';
export const AVAILABLE_NETWORKS = [NETWORK_MAINNET, NETWORK_RINKEBY];

// 0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b/0x2ef5B89bFD5BA8C3b15879106C57010aA7A32D06
// token 1/ token 0

// TOKEN_0_ADDRESS - token 0 from UNI pair
// https://app.uniswap.org/#/add/0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b/0x2ef5B89bFD5BA8C3b15879106C57010aA7A32D06

export const TOKEN_0_ADDRESS: Record<string, string> = {
  [NETWORK_RINKEBY]: '0x1af88215c5cfbf54048eb4448bdea68d09c64131',
  [NETWORK_MAINNET]: '0x6710c63432a2de02954fc0f851db07146a6c0312',
};

export const FEE: Record<string, string> = {
  [NETWORK_RINKEBY]: '3000',
  [NETWORK_MAINNET]: '10000',
};

export const TOKEN_1_ADDRESS: Record<string, string> = {
  // [NETWORK_RINKEBY]: '0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b', // USDC
  // [NETWORK_MAINNET]: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', // USDC
  [NETWORK_RINKEBY]: '0xc778417e063141139fce010982780140aa0cd5ab',
  [NETWORK_MAINNET]: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
};

// Get NFT balance...
// https://github.com/Uniswap/uniswap-v3-periphery/blob/main/deploys.md
export const NFT_POSITIONS_MANAGER_ADDRESS: Record<string, string> = {
  [NETWORK_RINKEBY]: '0xC36442b4a4522E871399CD717aBDD847Ab11FE88',
  [NETWORK_MAINNET]: '0xC36442b4a4522E871399CD717aBDD847Ab11FE88',
};

export const STAKING_REWARDS_ADDRESS: Record<string, string> = {
  [NETWORK_RINKEBY]: '0x1f98407aaB862CdDeF78Ed252D6f557aA5b0f00d',
  [NETWORK_MAINNET]: '0x1f98407aaB862CdDeF78Ed252D6f557aA5b0f00d',
  // [NETWORK_RINKEBY]: '0xc462aB5e66067153Bf1B368493E4744C1cA4BeC9',
  // [NETWORK_MAINNET]: '0x9213d5c6d737Fc2744db9d67037fE90D76Cb0F77',
};
// 'https://api.thegraph.com/subgraphs/name/vbstreetz/witswap-staking-rinkeby',
// [NETWORK_MAINNET]:
// 'https://api.thegraph.com/subgraphs/name/vbstreetz/witswap-staking',
//https://gateway.testnet.thegraph.com/api/[api-key]/subgraphs/id/0x8ad3cfccdfc9a72ca5eca98391771248b18d4275-0
//https://api.studio.thegraph.com/query/7276/mfg-univ3-dev/v1.0.0
export const SUBGRAPHS: Record<string, string> = {
  [NETWORK_RINKEBY]:
    'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3',
  [NETWORK_MAINNET]:
    'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3',
};

export const INCENTIVE: Record<string, any> = {
  [NETWORK_RINKEBY]: {
    id: 'test',
    reward: '5000000000000000000000000',
    ended: false,
    startTime: '1630759796',
    endTime: '1633046400',
    refundee: '0x8aD3cfCCdfC9A72CA5ecA98391771248b18d4275',
  },
  // TODO: DONE
  [NETWORK_MAINNET]: {
    id: 'mfg-mainnet-5m',
    reward: '5000000000000000000000000',
    ended: false,
    startTime: '1633046400',
    endTime: '1648771199',
    refundee: '0x76e9AaE0A2390549762c2bEa095AcD70303d9A53',
  },
  [NETWORK_MAINNET + 'mfg-mainnet-test']: {
    id: 'mfg-mainnet-test',
    reward: '1000000000000000000000',
    ended: false,
    startTime: '1632976217',
    endTime: '1632997051',
    refundee: '0x76e9AaE0A2390549762c2bEa095AcD70303d9A53',
  },
};

export const POOL: Record<string, string> = {
  [NETWORK_RINKEBY]: '0x136be2976b61b2b22d92209008d706e82b185f85',
  [NETWORK_MAINNET]: '0x3c8e1997d9f93c989d4d7660b6ca52c451ad7554', // TODO: Verified done
};
export const COINGECKO: Record<string, string> = {
  [NETWORK_RINKEBY]:
    'https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=0x6710c63432a2de02954fc0f851db07146a6c0312&vs_currencies=usd',
  [NETWORK_MAINNET]:
    'https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=0x6710c63432a2de02954fc0f851db07146a6c0312&vs_currencies=usd', // TODO: not yet
};

// POOL address to get TVL
// 0x3c8e1997d9f93c989d4d7660b6ca52c451ad7554
