import { createMuiTheme } from '@material-ui/core/styles';
import { BORDER_RADIUS } from 'config';

// import NexaThin from 'fonts/Nexa-Thin.woff2';

const nexaThin = {
  fontFamily: 'Nexa',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
    local('Nexa'),
    url(//db.onlinewebfonts.com/c/909ceff46b1e8b0a46261d3737e348c6?family=Nexa+Regular) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export default createMuiTheme({
  typography: {
    fontSize: 16,
    fontFamily: [
      'Nexa',
      'Sans-serif',
      // 'Inter var',
      // 'sans-serif',
      // 'Source Code Pro',
      // 'Helvetica',
      // 'Arial',
      // 'sans-serif',
    ].join(','),
  },
  palette: {
    type: 'dark',
    background: {
      default: '#212429',
      paper: '#191b1f',
    },
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#FDBD12',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          color: '#222222',
          // background:
          //   'url("https://i.imgur.com/mqTPH1y.png") center top / cover no-repeat',
          // background:
          //   'url("https://i.imgur.com/wHHS6BV.png") center top / cover no-repeat',
          backgroundColor: '#111140',
          background:
            'url("https://i.imgur.com/wHHS6BV.png") center top / cover no-repeat, url("https://i.imgur.com/5gJYntH.jpg") center top / cover no-repeat',
        },
      },
    },

    MuiCardContent: {
      root: {
        padding: 10,
      },
    },
    MuiCardActions: {
      root: {
        padding: 0,
        paddingTop: 10,
        paddingBottom: 10,
      },
    },
    MuiButton: {
      root: {
        borderRadius: BORDER_RADIUS,
      },
      contained: {
        boxShadow: '0px 0px 12px 2px rgb(253 189 18 / 44%)',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: BORDER_RADIUS,
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: BORDER_RADIUS,
      },
    },
    MuiTypography: {
      h2: {
        fontWeight: 'bold',
      },
      h5: {
        fontWeight: 'bold',
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottomColor: '#313131',
        },
        '&:after': {
          borderBottomColor: '#313131',
        },
      },
    },
  },
});
