import React, { FC, useCallback, useEffect, useState } from 'react';
import * as ethers from 'ethers';
import { Box, Button, Chip, Tooltip } from '@material-ui/core';
import CountUp from 'react-countup';
// import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import { formatUnits } from 'utils/big-number';
import { useWallet } from 'contexts/wallet';
import { LiquidityPosition } from '../../utils/types';
import { useContracts } from '../../contexts/contracts';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { formatTimestamp } from 'utils/time';
import { useData } from '../../contexts/data';
import { useNotifications } from '../../contexts/notifications';
import _ from 'lodash';
import Link from '@material-ui/core/Link';

const expiredCampaign = true;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      // backgroundColor: theme.palette.info.dark,
      // opacity: 0.8,
      padding: 12,
      // maxWidth: 345
      [theme.breakpoints.down('sm')]: {
        padding: 10,
      },
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      // backgroundColor: red[500],
    },
    title: {
      fontSize: 30,
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },
    maxButton: {
      height: 35,
    },
    // depositButtonCell: {
    //   width: '100%',
    //   // padding: 5,
    // },
    depositButton: {
      // backgroundColor: theme.palette.secondary.dark,
      width: '100%',
      // width: 100,
    },
    depositWrapper: {
      // backgroundColor: theme.palette.secondary.dark,
      // padding: 10,
      // width: 100,
    },
    details: {
      width: '100%',
      backgroundColor: 'transparent',
      boxShadow: '0 0 0',
    },
    content: {
      paddingBottom: 15,
      textAlign: 'center',
      // padding: 10,
      // width: 100,
    },
    cover: {
      width: 100,
    },
    description: {
      color: 'rgba(255, 255, 255, 0.7)',
      fontWeight: 300,
    },

    largeIcon: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      [theme.breakpoints.down('sm')]: {
        width: '40px',
        height: '40px',
      },
    },

    rightIcon: {
      marginLeft: '32px',
      padding: 3,
      [theme.breakpoints.down('sm')]: {
        marginLeft: '34px',
      },
    },
    viewPool: {
      position: 'absolute',
      top: '60px',
      fontSize: '10px',
      [theme.breakpoints.down('sm')]: {
        top: '42px',
      },
    },
  })
);

const LiquidityPositionTableRow: FC<{
  position: LiquidityPosition;
  history: any;
  unigraphData: any;
  globalData: any;
  incentives: any;
  currentIncentiveId: any;
}> = ({
  position,
  history,
  incentives,
  currentIncentiveId,
  unigraphData,
  globalData,
}) => {
  const classes = useStyles();
  const { address } = useWallet();
  const { token0Decimals } = useContracts();
  const {
    token0Address,
    token1Address,
    token0Symbol,
    token1Symbol,
  } = useContracts();

  // const countUpRef = React.useRef(null);
  // const { start, pauseResume, reset, update } = useCountUp({
  //   startOnMount: true,
  //   ref: countUpRef,
  //   start: 0,
  //   end: 0,
  //   delay: 0,
  //   duration: 2,
  //   separator: ',',
  //   onReset: () => console.log('Resetted!'),
  //   onUpdate: () => console.log('Updated!'),
  //   onPauseResume: () => console.log('Paused or resumed!'),
  //   onStart: ({ pauseResume }) => console.log(pauseResume),
  //   onEnd: ({ pauseResume }) => console.log(pauseResume),
  // });
  //
  // if (countUpRef && countUpRef.current !== null) {
  //   console.log('countUpRef', {
  //     countUpRef,
  //   });
  //   update(999999);
  //   // start();
  // }
  //xxx
  // const classes = useStyles();
  const { stakingRewardsContract } = useContracts();
  const {
    currentIncentiveRewardTokenSymbol,
    currentIncentiveRewardTokenDecimals,
    currentIncentive,
  } = useData();
  // const { address } = useWallet();
  const { tx } = useNotifications();

  const [reward, setReward] = useState(ethers.BigNumber.from(0));
  const [isClaiming, setIsClaiming] = useState(false);

  useEffect(() => {
    if (!(stakingRewardsContract && currentIncentive && address)) return;

    let isMounted = true;
    const unsubs = [
      () => {
        isMounted = false;
      },
    ];

    const load = async () => {
      const reward = await stakingRewardsContract.rewards(
        currentIncentive.key.rewardToken,
        address
      );
      if (isMounted) setReward(reward);
    };

    const subscribe = () => {
      const tokenUnstakedEvent = stakingRewardsContract.filters.TokenUnstaked();
      stakingRewardsContract.on(tokenUnstakedEvent, load);
      unsubs.push(() => {
        stakingRewardsContract.off(tokenUnstakedEvent, load);
      });

      const rewardClaimedEvent = stakingRewardsContract.filters.RewardClaimed();
      stakingRewardsContract.on(rewardClaimedEvent, load);
      unsubs.push(() => {
        stakingRewardsContract.off(rewardClaimedEvent, load);
      });
    };

    load();
    subscribe();

    return () => {
      unsubs.map((u) => u());
    };
  }, [stakingRewardsContract, currentIncentive, address]);

  const claim = async () => {
    if (!(stakingRewardsContract && currentIncentive)) return;

    try {
      setIsClaiming(true);
      const reward = await stakingRewardsContract.rewards(
        currentIncentive.key.rewardToken,
        address
      );
      await tx('Claiming...', 'Claimed!', () =>
        stakingRewardsContract.claimReward(
          currentIncentive.key.rewardToken,
          address,
          reward
        )
      );
    } catch (e) {
      console.warn(e);
    } finally {
      setIsClaiming(false);
    }
  };
  //xxx

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const stake = useCallback(async () => {
    history.push(`/stake/${position.tokenId}`);
  }, [position.tokenId, history]);

  const unstake = useCallback(async () => {
    history.push(`/unstake/${position.tokenId}`);
  }, [position.tokenId, history]);

  const withdraw = useCallback(async () => {
    history.push(`/withdraw/${position.tokenId}`);
  }, [position.tokenId, history]);

  const incentive = incentives.filter(
    (i: any) => i.id === currentIncentiveId
  )[0];

  // console.log('incentive', {
  //   incentive,
  //   position,
  // });

  const pendingRewards = Number(
    formatUnits(position.reward, token0Decimals, 0).replaceAll(',', '')
  );

  const pendingRewardsSmall = Number(
    formatUnits(position.reward, token0Decimals, 2).replaceAll(',', '')
  );

  // console.log(
  //   'reward raw',
  //   Number(formatUnits(position.reward, token0Decimals, 8).replaceAll(',', ''))
  // );

  const poolName = _.get(position, 'tokenData.name', '');

  return (
    <>
      <Box p={0} mt={5} width={1}>
        <Card className={classes.root}>
          <CardContent>
            <Box className='flex flex-grow justify-space'>
              <Box width={'90px'}>
                <div
                  style={{
                    position: 'relative',
                  }}
                >
                  <Avatar
                    className={`${classes.largeIcon} ${classes.rightIcon}`}
                    style={{
                      position: 'absolute',
                      zIndex: 10,
                      background: 'rgb(0,0,0,0.5)',
                    }}
                    src={'/images/icon.png'}
                  ></Avatar>
                  <Avatar
                    className={classes.largeIcon}
                    style={{
                      position: 'absolute',
                    }}
                    src={'https://i.imgur.com/oB4WqwA.png'}
                  ></Avatar>

                  <div className={classes.viewPool}>
                    <Tooltip
                      title={`Click to view your LP details ${poolName}`}
                    >
                      <Link
                        href={`https://app.uniswap.org/#/pool/${position.tokenId}`}
                        rel='noopener noreferrer'
                        target='_blank'
                        // underline='none'
                      >
                        View #{position.tokenId}
                      </Link>
                    </Tooltip>
                  </div>
                </div>
              </Box>
              <Box>
                <Typography
                  align={'center'}
                  variant='h3'
                  component='h2'
                  className={classes.title}
                  color='textPrimary'
                  gutterBottom
                >
                  Uniswap V3 {token0Symbol}-{token1Symbol} Liquidity Program
                </Typography>
                <Typography
                  align={'center'}
                  variant='subtitle1'
                  component='h3'
                  className={classes.description}
                >
                  <Chip label='EXPIRED' style={{ backgroundColor: 'red' }} />
                  {`  
                
                 ${formatTimestamp(incentive.key.startTime)} - 
                ${formatTimestamp(incentive.key.endTime)}
                ${incentive.ended ? 'ENDED' : ''}
                `}
                </Typography>
                <Typography
                  align={'center'}
                  variant='subtitle2'
                  component='h4'
                  // className={classes.description}
                >
                  {`
                  Reward Distribution = ${formatUnits(
                    incentive.reward.toString(),
                    18,
                    0
                  )} MFG
                  `}{' '}
                  (
                  <CountUp
                    separator={','}
                    prefix='$'
                    // redraw={true}
                    duration={2}
                    preserveValue={true}
                    // suffix=' USD'
                    end={globalData && globalData.total_mfg_reward_usd}
                  />
                  )
                </Typography>
              </Box>
              <Box>
                <Avatar
                  className={classes.largeIcon}
                  src={'https://i.imgur.com/axCsOTc.png'}
                ></Avatar>
                {!position.staked ? (
                  <span onClick={withdraw} style={{ fontSize: 11 }}>
                    Withdraw ⓘ
                  </span>
                ) : null}
              </Box>
            </Box>
          </CardContent>

          <CardContent>
            <Typography variant='body2' color='textSecondary' component='p'>
              <Box className='flex flex-grow'>
                <Box mr={2} width={1 / 3}>
                  <Card className={classes.details}>
                    <div>
                      <CardContent className={classes.content}>
                        <Typography component='h5' variant='h5'>
                          <CountUp
                            separator={','}
                            prefix='$'
                            // redraw={true}
                            duration={1}
                            preserveValue={true}
                            // suffix=' MFG'
                            end={Number(
                              parseFloat(
                                unigraphData.totalValueLockedUSD
                              ).toFixed(0)
                            )}
                          />
                        </Typography>
                        <Typography variant='subtitle1' color='textSecondary'>
                          TVL
                        </Typography>
                      </CardContent>
                    </div>
                    {/*  <CardMedia*/}
                    {/*    className={classes.cover}*/}
                    {/*    image='/images/icon.png'*/}
                    {/*    title='Live from space album cover'*/}
                    {/*  />*/}
                  </Card>
                </Box>
                <Box width={1 / 3} mr={2}>
                  <Card className={classes.details}>
                    <div>
                      <CardContent className={classes.content}>
                        <Typography component='h5' variant='h5'>
                          {!position.reward.isZero() && pendingRewards > 0 ? (
                            <>
                              <CountUp
                                separator={','}
                                // prefix=''
                                // redraw={true}
                                duration={2}
                                preserveValue={true}
                                suffix=' MFG'
                                end={pendingRewards}
                              />
                            </>
                          ) : (
                            <>0.0</>
                          )}
                          {/*{!position.reward.isZero() &&*/}
                          {/*pendingRewardsSmall > 0 ? (*/}
                          {/*  <>{pendingRewardsSmall}</>*/}
                          {/*) : (*/}
                          {/*  <>0.0</>*/}
                          {/*)}*/}
                        </Typography>
                        <Typography variant='subtitle1' color='textSecondary'>
                          {position.staked ? (
                            `Pending rewards`
                          ) : (
                            <a href={'#'} onClick={stake}>
                              (Stake LP to earn now)
                            </a>
                          )}
                        </Typography>
                      </CardContent>
                    </div>
                    {/*  <CardMedia*/}
                    {/*    className={classes.cover}*/}
                    {/*    image='/static/images/cards/live-from-space.jpg'*/}
                    {/*    title='Live from space album cover'*/}
                    {/*  />*/}
                  </Card>
                </Box>
                <Box width={1 / 3}>
                  <Card className={classes.details}>
                    <div>
                      <CardContent className={classes.content}>
                        <Typography component='h5' variant='h5'>
                          {formatUnits(
                            reward,
                            currentIncentiveRewardTokenDecimals,
                            0
                          )}{' '}
                          {currentIncentiveRewardTokenSymbol}
                        </Typography>
                        <Typography variant='subtitle1' color='textSecondary'>
                          {`Accrued rewards`}
                        </Typography>
                      </CardContent>
                    </div>
                    {/*  <CardMedia*/}
                    {/*    className={classes.cover}*/}
                    {/*    image='/static/images/cards/live-from-space.jpg'*/}
                    {/*    title='Live from space album cover'*/}
                    {/*  />*/}
                  </Card>
                </Box>
              </Box>
            </Typography>
          </CardContent>
          <CardActions className={classes.depositWrapper}>
            {position.staked ? (
              <>
                <Box pl={1} pr={1} width={1 / 2}>
                  <Button
                    color='primary'
                    onClick={withdraw}
                    size={'medium'}
                    variant={'outlined'}
                    className={classes.depositButton}
                    // startIcon={<AssignmentReturnedIcon />}
                    disabled={position.owner === address}
                  >
                    Withdraw
                  </Button>
                </Box>
              </>
            ) : (
              // <Box ml={5} width={1 / 2}>
              //   <Button
              //     color={'primary'}
              //     onClick={unstake}
              //     size={'medium'}
              //     className={classes.depositButton}
              //     // startIcon={<RemoveCircleOutlineIcon />}
              //     variant={'outlined'}
              //   >
              //     Unstake
              //   </Button>
              // </Box>
              <>
                <Box pl={1} pr={1} width={1 / 2}>
                  <Button
                    disabled={expiredCampaign}
                    color={'secondary'}
                    onClick={stake}
                    size={'medium'}
                    className={classes.depositButton}
                    // startIcon={<AddCircleOutlineIcon />}
                    variant={'contained'}
                  >
                    Stake
                  </Button>
                </Box>
              </>
            )}
            {/* Claim */}
            <Box pl={1} pr={1} width={1 / 2}>
              <Button
                color='secondary'
                variant='contained'
                onClick={claim}
                className={classes.depositButton}
                disabled={isClaiming || reward.isZero()}
              >
                {isClaiming ? 'Claiming...' : 'Claim Accrued rewards'}
              </Button>
            </Box>
          </CardActions>
        </Card>
      </Box>
    </>
  );
};

export default LiquidityPositionTableRow;
